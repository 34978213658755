import * as f from './functions';
import { throttle } from 'lodash-es';
export const CSS_ROOT = document.documentElement;
export const CSS_DOCUMENT_ROOT = getComputedStyle(CSS_ROOT);
export const isMacIos = /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform);
export const isIOS = /(iPhone|iPod|iPad)/i.test(navigator.platform);
export const MQ = {
  mobile_small: CSS_DOCUMENT_ROOT.getPropertyValue('--mobile-small'),
  mobile: CSS_DOCUMENT_ROOT.getPropertyValue('--mobile'),
  tablet: CSS_DOCUMENT_ROOT.getPropertyValue('--tablet'),
  desktop: CSS_DOCUMENT_ROOT.getPropertyValue('--desktop'),
};
export const MQ_IS = (function () {
  const _MQ_IS = {
    mobile_small: $(window).width() <= MQ.mobile_small,
    mobile: $(window).width() <= MQ.mobile,
    tablet: $(window).width() <= MQ.tablet,
    desktop: $(window).width() <= MQ.desktop,
  };
  $(window).resize(
    throttle((num) => {
      _MQ_IS.mobile_small = $(window).width() <= MQ.mobile_small;
      _MQ_IS.mobile = $(window).width() <= MQ.mobile;
      _MQ_IS.tablet = $(window).width() <= MQ.tablet;
      _MQ_IS.desktop = $(window).width() <= MQ.desktop;
    }, 250)
  );
  return _MQ_IS;
})();
export const HTML_FONT_SIZE_PERCENT = CSS_DOCUMENT_ROOT.getPropertyValue(
  '--font-size-html-percent'
);

export const TR_SPEED = {
  slow: parseInt(CSS_DOCUMENT_ROOT.getPropertyValue('--speed-slow'), 10),
  normal: parseInt(CSS_DOCUMENT_ROOT.getPropertyValue('--speed-normal'), 10),
  fast: parseInt(CSS_DOCUMENT_ROOT.getPropertyValue('--speed-fast'), 10),
};

export const LOCALES = {
  bg: 'bg',
  en: 'en',
};

export const TRANSLATIONS = {};
f.setMarginTop();
$(window).resize(
  throttle((e) => {
    f.setMarginTop();
  }, 250)
);

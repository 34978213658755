import * as c from './constants';

export function calculateRem(pixels) {
  return pixels / (c.HTML_FONT_SIZE_PERCENT * 16);
}
$.fn.isInViewport = function () {
  var elementTop = $(this).offset().top;
  var elementBottom = elementTop + $(this).outerHeight();
  var viewportTop = $(window).scrollTop();
  var viewportBottom = viewportTop + $(window).height();
  return elementBottom > viewportTop && elementTop < viewportBottom;
};

export const toggle = (
  target,
  destinations,
  classToggle,
  globalHandler = true,
  renameTarget = null
) => {
  const $target = $(target);
  const $destinations = $(destinations.join());
  $target.on('click', function (e) {
    e.stopPropagation();
    $destinations.toggleClass(classToggle);
    $target.toggleClass(classToggle);
  });
  if (globalHandler) {
    $(document).click(function (e) {
      e.stopPropagation();
      let s2 = true;
      if ($target.is('.c-filter .btn.toggle')) {
        s2 = !$('.select2-container--open *').is(e.target);
      }
      if (
        !$destinations.is(e.target) &&
        s2 &&
        $destinations.has(e.target).length === 0
      ) {
        $destinations.removeClass(classToggle);
        $target.removeClass(classToggle);
      }
    });
  }
};
export const renderPatients = (data, type = '') => {
  if (!data) return;
  const { patients, patientsHeader, minutes, minutesHeader, list } = data;
  const $patientsHeaderContainer = $('.part.patients .heading');
  const $patientsCountContainer = $('.part.patients .count');
  const $minutesHeaderContainer = $('.part.minutes .heading');
  const $minutesCountHeaderContainer = $('.part.minutes .count');
  const $listContainer = $('.c-list ul');
  const $btn = $('.info .btn');
  if (patients) {
    $patientsHeaderContainer.html(patientsHeader);
  }
  if (patientsHeader) {
    $patientsCountContainer.html(patients);
  }
  if (minutes) {
    $minutesHeaderContainer.html(minutesHeader);
  }
  if (minutesHeader) {
    $minutesCountHeaderContainer.html(minutes);
  }
  if (list) {
    $listContainer.html(list);
  }
  if (type) {
    $btn.remove();
  }
};

export const setMarginTop = () => {
  const headerHeight = $('#header').height();
  let top = `${(headerHeight + 40) / 10}` + 'rem';
  if (c.MQ_IS.mobile) {
    top = `${(headerHeight + 20) / 10}` + 'rem';
  }
  c.CSS_ROOT.style.setProperty('--main-margin-top', `${top}`);
  $('main').css('margin-top', top);
};

import { CONSTANTS as c, functions as f } from '../helpers';
import Core from './Core';
import InputField from './InputField';
import { controllers } from '../controllers';
import AutoNumeric from 'autonumeric';
const isDev = Core.isDev;

export default class Form {
  constructor(options) {
    this.$root = options.root;
    this.$form = this.$root;
    this.$inputFields = this.$root.find('textarea, input:not(.datepicker, .timepicker)');
    this.$selectFields = this.$root.find('select.select2');
    this.$datePickerFields = this.$root.find('input.datepicker');
    this.$timePickerFields = this.$root.find('input.timepicker');
    this.$submitBtn = this.$root.find('button[type="submit"]');
    this.$submitBtnText = this.$submitBtn.find('span');
    this.$questioningPopupUnreqistered = this.$root.closest('.s-popup-questioning-unregistered');
    this.$checkAllBtn = $('.u-special-wrapper .btn');
    this.$checkAllInputs = $('.u-special-wrapper input[type="checkbox"');
    this.$yearBtns = $('.years-wrapper .btn');
    this.$yearPlanSection = $('.s-year-plan');
    this.$prices = $('.c-pricing');
    this.$yearValue = $('#year');
    this.$priceValue = $('#price');
    this.$clientValue = $('#client');
    this.$yearPlanSubmitBtn = $(".s-year-plan [type='submit']");
    this.$steps = this.$root.find('.step');
    this.stepList = this.$steps.toArray();
    this.stepCount = this.$steps.length;
    this.formData = {};
    this.inputList = [];
    this.selectList = [];
    this.dateTimeList = [];
    this.allFieldsList = [];
    this.hasSteps = options.steps;
    this.currStep = 0;
  }
  init() {
    const self = this;

    new AutoNumeric('#tel', {
      decimalPlaces: 0,
      digitGroupSeparator: '',
      leadingZero: 'keep',
      minimumValue: '0',
      maximumValue: '10000000000000000000',
    });
    new AutoNumeric('#cost', {
      allowDecimalPadding: 'floats',
      decimalPlaces: 2,
      modifyValueOnWheel: true,
      wheelStep: 0.01,
      leadingZero: 'allow',
      decimalCharacter: '.',
      digitGroupSeparator: '',
      minimumValue: '0',
      maximumValue: '100000',
    });
    new AutoNumeric('#number', {
      decimalPlaces: 0,
      digitGroupSeparator: '',
      leadingZero: 'keep',
      minimumValue: '0',
      maximumValue: '10000000000000000000',
    });


    $('.upload').on('change', function (event) {
      const reader = new FileReader();
      reader.onload = function () {
        if(document.querySelector('.preview')) {
          document.querySelector('.preview').remove();
        }
        const previewEl = document.createElement('div');
        previewEl.classList.add('preview');
        document.querySelector('.upload-wrapper').prepend(previewEl);
        const preview = document.querySelector('.preview');
        const img = document.createElement('img');
        img.src = this.result;
        const clear = document.createElement('span');
        clear.classList.add('clear');
        preview.innerHTML = '';
        preview.appendChild(img);
        preview.appendChild(clear);
      };
      reader.readAsDataURL(event.target.files[0]);
      let name = self.getFileNameWithExt(event);
      if (!name) {
        name = 'Прикачи снимка';
      }
      $('.upload-text span').text(name);
    });
    $('body').on('click', '.clear', function () {
      const preview = document.querySelector('.preview');
      if (preview) preview.remove();
      $('form').find('[type="file"]').val('');
      $('.upload-text span').text('Прикачи снимка');
    });

    self.$submitBtn.on('click submit', function (e) {
      e.preventDefault();
      self.prepareData();
      self.submitData();
    });
    return self;
  }

  getFileNameWithExt(event) {
    if (!event || !event.target || !event.target.files || event.target.files.length === 0) {
      return;
    }
    const name = event.target.files[0].name;
    return name;
  }

  prepareData() {
    const self = this;
    self.formData = new FormData();
    const data = self.$form.serializeArray();
    $(document).find('[type="text"],[type="email"],textarea').css('border: 1px solid #ccc');
    for (let i in data) {
      const name = data[i].name;
      self.formData.append(name, data[i].value);
    }
    const inputFile = document.querySelector('.upload');
    self.formData.append('file', inputFile.files[0]);
    return self;
  }
  submitData() {
    const self = this;
    self.prepareData();
    self.$submitBtn.attr('disabled', true);
    let url = '';
    if (self.$steps.length) {
      url = self.$root.find(`.step-${self.currStep + 1}`).data('url');
    } else {
      url = self.$form.attr('action');
    }
    $.ajax({
      url,
      type: self.$form.attr('method'),
      headers: {
        'X-CSRF-TOKEN': $('input[name="_token"]').val(),
      },
      data: self.formData,
      contentType: false,
      processData: false,
      beforeSend: function () {
        self.clearErrors();
        const loaderHtml = '<div class="page-loader-wrapper"><div class="page-loader"></div></div>';
        $('body').append(loaderHtml);
      },
      success: function (response) {
        if (response.hasOwnProperty('redirect')) {
          window.location = response.redirect;
        } else {
          self.successHandler(response);
        }
      },
      error: function (jqXHR, textStatus, errorThrown) {
        self.errorHandler(jqXHR, textStatus, errorThrown, self);
      },
      complete: function (data) {
        self.$submitBtn.attr('disabled', false);
        $('.page-loader-wrapper').remove();
        $('.g-recaptcha').each(function (index, element) {
          grecaptcha.reset(index);
        });
      },
    });
    return self;
  }
  successHandler(response) {
    const self = this;
    self.renderSuccessPopup(response.message);
    self.reset();
    return self;
  }
  renderSuccessPopup(message) {
    if (!message) return;
    const self = this;
    $.magnificPopup.open({
      items: {
        src: $('.success-message'),
        type: 'inline',
      },
    });
    return self;
  }
  errorHandler(jqXHR, textStatus, errorThrown, form) {
    const self = this;
    const formId = $(self).attr('id');
    isDev ? console.log(jqXHR, textStatus, errorThrown) : '';
    if (jqXHR.status == 422) {
      if (!jqXHR?.responseJSON) return;
      if (jqXHR?.responseJSON?.errors) {
        // if (!jqXHR?.responseJSON?.data?.results) return;
        const response = jqXHR?.responseJSON?.errors; // ?.data?.results;
        isDev ? console.log('response: ', response) : '';
        const step = self.stepList[self.currStep];
        let errInCurrStep = false;
        for (const fieldName in response) {
          if (response.hasOwnProperty(fieldName)) {
            const msg = response[fieldName];
            console.log('🚀 ~ Form ~ errorHandler ~ msg:', msg);

            if (fieldName == 'g-recaptcha-response') {
              self.renderErrorReCAPTCHA('#recaptcha-error', msg);
            } else {
              self.renderError(fieldName, msg, formId);
            }
            if (
              (fieldName == 'g-recaptcha-response' &&
                $(step).find('[name="g-recaptcha-response"]').length) ||
              $(step).find(`[name^=${fieldName}`).length
            ) {
              errInCurrStep = true;
            }
          }
        }
        if (!errInCurrStep) {
          self.changeStep(+1);
        }
      }
      if (jqXHR?.responseJSON?.message) {
        let message = jqXHR?.responseJSON?.message;
        if (!message) message = Core.translations.server_error_message;
        if (!message) message = 'Възникна грешка!';
        $.magnificPopup.open({
          items: {
            src: $(` <div class="error-message popup-inline mfp-hide">
            <h2 class="u-tac">${message}</h2>
          </div>`),
            type: 'inline',
          },
        });
      }
    } else {
      $.magnificPopup.open({
        items: {
          src: $('.error-message'),
          type: 'inline',
        },
      });
    }

    return self;
  }
  renderErrorReCAPTCHA(element, msgs) {
    const self = this;
    const $field = $(document).find(element);
    const $fieldFromGroup = $field.closest('.form-group-captcha');
    const $fieldError = $('<span class="error-msg" style="text-align: center;"></span>');
    $fieldFromGroup.append($fieldError);
    $fieldFromGroup.addClass('error');
    msgs.forEach((errorMsg) => {
      $fieldError.append(errorMsg);
    });
    return self;
  }
  renderError(fieldName, msgs, formId) {
    const self = this;
    const $field = self.$form.find(`[name ^= ${fieldName}]`).first();
    const $fieldFromGroup = $field.closest('.form-group');
    const $fieldError = $('<span class="error-msg"></span>');
    $fieldFromGroup.append($fieldError);
    $fieldFromGroup.addClass('error');
    msgs.forEach((errorMsg) => {
      $fieldError.append(errorMsg);
    });
    return self;
  }

  clearErrors() {
    const self = this;
    const $fieldsFormGroup = self.$form.find('.form-group');
    const $fieldError = $fieldsFormGroup.find('.error-msg');
    $fieldsFormGroup.removeClass('error');
    $fieldError.remove();
    return self;
  }
  test() {
    const self = this;
    self.fill(true);
    self.allFieldsList.forEach((field) => {
      field.check();
    });
    return self;
  }
  fill(val = true) {
    const self = this;
    self.set(true);
    return self;
  }
  reset() {
    const self = this;
    self.set(false);
    self.clearErrors();
    self.allFieldsList.forEach((f) => {
      f.check();
      if (f.type === 'select') {
        f.reset();
      }
    });
    self.setStepState();
    self.$submitBtn.attr('disabled', false);
    setTimeout(() => {
      if (self.$questioningPopupUnreqistered.length > 0 && c.MQ_IS.mobile) {
        self.$questioningPopupUnreqistered.find('.aside').removeClass('u-hidden');
        self.$questioningPopupUnreqistered.find('.main').addClass('u-hidden');
      }
    }, 0);
    const preview = document.querySelector('.preview');
    if (preview) preview.remove();
    $('.upload-text span').text('Прикачи снимка');
    return self;
  }
  set(fill) {
    const self = this;
    self.$inputFields.toArray().forEach((f) => {
      if ($(f).is('input:checkbox')) {
        $(f).prop('checked', fill);
      } else if ($(f).is('input:radio')) {
      } else if ($(f).is('input:hidden')) {
      } else {
        if (!$(f).is('input[name="_token"]') && !$(f).is('input[name="_token"]')) {
          $(f).val(fill ? 'test' : '');
        }
      }
    });
    self.$selectFields.toArray().forEach((f) => {
      fill ? ($(f)[0].selectedIndex = 0) : ($(f)[0].selectedIndex = 1);
    });
    self.$datePickerFields.toArray().forEach((f) => {
      $(f).val(fill ? '01.01.2021' : '');
    });
    self.$timePickerFields.toArray().forEach((f) => {
      $(f).val(fill ? '00:00' : '');
    });
  }
  redirect(url) {
    const self = this;
    window.location.replace(url);
    return self;
  }
  changeStep(increment) {
    const self = this;
    if (self.currStep + increment < 0 && self.currStep + increment >= self.stepCount) {
      return self;
    }
    self.currStep += increment;
    self.setStepState();
    if ($('.mfp-container')[0]) $('.mfp-container')[0].scrollIntoView();

    if (self.$root.is('.s-popup-registration')) {
      if (self.currStep !== 0) {
        self.$root.find('.only-step-1, .heading-wrapper').hide();
      } else {
        self.$root.find('.only-step-1, .heading-wrapper').show(0);
      }
    }
    return self;
  }
  setStepState() {
    const self = this;
    for (let index = 0; index < self.stepCount; index++) {
      const step = self.stepList[index];
      if (index === self.currStep) {
        $(step).removeClass('hidden next prev');
      } else if (index < self.currStep) {
        $(step).addClass('hidden prev').removeClass('next');
      } else {
        $(step).addClass('hidden next').removeClass('prev');
      }
    }
    return self;
  }
}

import { CONSTANTS as c, functions as f } from '../helpers';
import { utilities } from '../utilities';
// import imgUrl from '@/images/email.png';
// console.log("🚀 ~ file: AnimationController.js:4 ~ imgUrl:", imgUrl)
// document.querySelector('.bg').style.background = `url(${imgUrl})`;

class AnimationController {
  init() {
    const self = this;

    $('.c-message .icon-close').on('click',function(){
        $(this).closest('.c-message').remove();
    });

    return self;
  }
}
export const controller = new AnimationController();

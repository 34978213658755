import { controller as FormController } from "./FormController";
import { controller as AnimationController } from "./AnimationController";
import { controller as MagnificPopupController } from "./MagnificPopupController";


export const controllers = {
    FormController,
    AnimationController,
    MagnificPopupController,
}

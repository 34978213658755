import Core from "./Core";

export default class InputField {
    constructor(el) {
        this.$el = el;
        this.type = "input";
        this.value = this.$el.val();
        this.required = this.$el.closest(".form-group").is(".required");
        this.disabled = this.$el.is(":disabled");
        this.errorMsg = Core.translations.error_latin_character
            ? Core.translations.error_latin_character
            : "Please, use latin!";
    }

    init() {
        const self = this;
        if (self.value) {
            self.check();
        }
        let visited = false;

        self.$el.on("focusin", function () {
            self.$el.addClass("active");
        });
        if (
            !self.$el.is('[type="file"]') &&
            !self.$el.is('[type="checkbox"]') &&
            !self.$el.is('[type="radio"]')
        ) {
            self.$el.on("input", function (e) {
                return self.preventCharacters();
            });
        }
        self.$el.on("focusout", function () {
            self.getValue();
            self.check();
            visited = true;
            self.$el.removeClass("active");
        });
        if (self.$el.is(".revealer") && self.$el.is("[type=checkbox]")) {
            self.$el.on("change", function (e) {
                self.getValue();
                self.check();
            });
        }
        if (self.$el.is(".revealer") && self.$el.is("[type=radio]")) {
            const name = self.$el.attr("name");
            $(`input[name="${name}"]`).on("change", function () {
                const $revealed = self.$el
                    .closest(".form-check")
                    .siblings(".form-group");
                if ($(this).is(".revealer")) {
                    $revealed.show();
                    $revealed.find("input").attr("disabled", false);
                } else {
                    $revealed.hide();
                    $revealed.find("input").attr("disabled", true);
                }
            });
        }
        return self;
    }
    preventCharacters() {
        const self = this;
        self.value = self.$el.val();
        const specials = /[^A-Za-z 0-9!?~@#\$%\^\&*\)\(\{\}\[\]\|\\+=,._-]/g;
        if (specials.test(self.value)) {
            self.$el.val("");
            self.renderError();
            setTimeout(function () {
                self.removeError();
            }, 5000);
        } else {
            self.removeError();
        }
    }
    getValue() {
        const self = this;
        self.value = self.$el.val();
        return self;
    }
    check() {
        const self = this;
        self.getValue();
        if (self.$el.is(".revealer")) {
            const $revealed = self.$el
                .closest(".form-check")
                .siblings(".form-group");
            if (self.$el.is(":checked")) {
                $revealed.show();
                $revealed.find("input").attr("disabled", false);
            } else {
                $revealed.hide();
                $revealed.find("input").attr("disabled", true);
            }
        }
        // if (self.value.length) {
        //     self.$el.hasClass('filled') ? '' : self.$el.addClass('filled');
        // } else {
        //     self.$el.removeClass('filled')
        // }
        return self;
    }
    renderError() {
        const self = this;
        const $fieldFromGroup = self.$el.closest(".form-group");
        if ($fieldFromGroup.hasClass("error")) return;
        const $fieldError = $('<span class="error-msg"></span>');
        $fieldFromGroup.append($fieldError);
        $fieldFromGroup.addClass("error");
        $fieldError.append(self.errorMsg);
        return self;
    }
    removeError() {
        const self = this;
        self.$el.closest(".form-group").removeClass("error");
        self.$el.closest(".form-group").find(".error-msg").remove();
        return self;
    }
}

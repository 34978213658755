import * as vendor from "./vendors";
import { controllers } from "./controllers";
import { utilities } from "./utilities";

const isDev = utilities.Core.isDev;

isDev ? console.time("content") : "";
isDev ? console.time("load") : "";
$(document).ready(function () {
    // controllers.AnimationController.init();
    controllers.FormController.init();
    controllers.MagnificPopupController.init();
    isDev ? console.timeEnd("content") : "";
});
$(window).on("load", function () {
    isDev ? console.timeEnd("load") : "";
});

import * as CONSTANT from "../helpers/constants";
import { CONSTANTS } from '../helpers';

class Core {
    constructor() {
        this.locale = this.getLocale();
        // eslint-disable-next-line no-process-env
        this.isProd = process.env.NODE_ENV === "production";
        this.isDev = process.env.NODE_ENV === "development";
        this.token = null;
        this.baseUrl = '';
        this.translations = {};
    }
    init() {
        const self = this;

        self.token = $('meta[name="csrf-token"]').attr("content");
        self.setEmptyAnkerTag();
        // self.goBack();
        self.getTranslations();
        if (CONSTANTS.isMacIos) {
            $('body').addClass('mac-ios');
            if (CONSTANTS.MQ_IS.mobile) {
                $('.s-text-type-5 .item a.learn-more').on('touchstart', function(e) {
                    window.location.href = $(this).attr('href');
                });
            }
        }

        return self;
    }
    setEmptyAnkerTag() {
        $('body').find('a').toArray()
            .filter((a) => !$(a).attr('href'))
            .map((a) => $(a).attr('href', 'javascript:void(0)'));
    }
    getLocale() {
        const lang = $("html").attr("lang");
        if (lang !== "bg" && lang !== "en") {
            return CONSTANT.LOCALES.en;
        }
        return CONSTANT.LOCALES[lang];
    }
    addId() {
        return Math.random().toString(36).substr(2, 9);
    }
    getDigits(number, precision = 2) {
        return +parseFloat(number).toFixed(2);
    }
    loadData() {
        const self = this;
        let url = self.baseUrl + '/jsons/data.json';
        return $.ajax({
            type: "GET",
            url,
        })
            .then(function(res) {
                if (res) {
                    self.data = { ...res };
                    self.isDev ? console.log('self.data', self.data) : '';
                }
            }).catch(function(res) {
                self.isDev ? console.log('res', res) : '';
                self.data = null;
            });
    }
    getCurrMediaQuery() {
        const windowWidth = $(window).width();
        for (const key in CONSTANT.MQ) {
            if (CONSTANT.MQ.hasOwnProperty(key)) {
                const query = parseInt(CONSTANT.MQ[key], 10);
                if (windowWidth < query) {
                    return key;
                }
            }
        }
        return 'desktop';
    }
    getTranslations() {
        const self = this;
        const $tran = $("#translations");
        if ($tran.length) {
            $tran.find("span").toArray().forEach((t) => {
                self.translations[$(t).attr("id")] = $.trim($(t).text());
            });
        }
        self.isDev ? console.log("self.translations: ", self.translations) : '';
        return self;
    }
}
const CORE = new Core();
CORE.init();
export default CORE;

import { utilities } from "../utilities";
import { CONSTANTS } from "../helpers";
const isDev = utilities.Core.isDev;

class FormController {
    constructor() {
        this.formTypes = {
            $default: $("form"),
        };
    }
    init() {
        const self = this;
        $.each(self.formTypes.$default, function (index, value) {
            let form = new utilities.Form({ root: $(value), steps: false });
            form.init();
        });
    }
}

export const controller = new FormController();

import { utilities } from "../utilities";
import { CONSTANTS } from "../helpers";
class MagnificPopupController {
    constructor() {}
    init() {
        let self = this;

        $('.open-popup-inline').toArray().forEach((el)=>{
            $(el).magnificPopup({
                type: "inline",
            });
        })
    }
}
export const controller = new MagnificPopupController();
